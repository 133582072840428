import { ManualCreate } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormHelperText,
    InputAdornment,
    ListItemButton,
    ListItemText,
    Paper,
    Skeleton,
    Stack,
    Typography,
    colors,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useSeqNumberByConnector } from '../../../hooks/chargePointHooks';
import { useRegistry } from '../../../services/GlobalRegistry';
import { ConnectorTypeText } from '../../connector/Standard';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';
import { useConnector, WithConnector } from '../../wrappers/WithConnector';
import { WithEVSE } from '../../wrappers/WithEVSE';
import * as yup from 'yup';
import {
    CustomerSuggestionContext,
    createCustomerSuggestionStore,
} from '../../customers/services/CustomerSuggestionContext';
import React from 'react';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { OperatorName } from '../../operators/elements/OperatorName';
import { DataWrap } from '../../event-log/components/DataWrap';

type Store = {
    open: boolean;
    show: () => void;
    hide: () => void;
};

export const useManualTransactionSubmitDialog = create<Store>((set, get) => ({
    open: false,
    show: () => set({ open: true }),
    hide: () => set({ open: false }),
}));

function ConnectorItem() {
    const chargePoint = useChargePoint();
    const connector = useConnector();

    const seqNumber = useSeqNumberByConnector(chargePoint, connector);
    const formik = useFormikContext<FormData>();

    const isSelected = useMemo(
        () => formik.values.connector === connector._id,
        [connector._id, formik.values.connector],
    );

    const onClick = () => {
        formik.setValues(values => ({ ...values, connector: connector._id }));
    };
    return (
        <ListItemButton selected={isSelected} component={Paper} sx={{ borderRadius: 1 }} onClick={onClick}>
            <Stack
                direction={'row'}
                alignItems="center"
                spacing={1}
                divider={<Divider orientation="vertical" flexItem />}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
            >
                <Typography fontSize={'inherit'} fontWeight={isSelected ? '700' : 'inherit'}>
                    {seqNumber}
                </Typography>
                <Typography fontSize={'inherit'} fontWeight={isSelected ? '700' : 'inherit'}>
                    <ConnectorTypeText type={connector.standard} />
                </Typography>
            </Stack>
        </ListItemButton>
    );
}

function ConnectorsBlock() {
    const formik = useFormikContext<FormData>();
    const chargePoint = useRegistry(store => store.chargePoints[formik.values.chargePoint]);

    if (!chargePoint) {
        return (
            <Stack direction={'row'} spacing={1}>
                <Skeleton variant="rounded" height={40} sx={{ flex: 1 }} />
                <Skeleton variant="rounded" height={40} sx={{ flex: 1 }} />
                <Skeleton variant="rounded" height={40} sx={{ flex: 1 }} />
            </Stack>
        );
    }
    return (
        <Stack direction={'row'} spacing={1}>
            <WithChargePoint id={chargePoint._id}>
                {chargePoint.evses.map(evse => (
                    <WithEVSE key={evse._id} id={evse._id}>
                        {evse =>
                            evse.connectors.map(connector => (
                                <WithConnector key={connector._id} id={connector._id}>
                                    <ConnectorItem />
                                </WithConnector>
                            ))
                        }
                    </WithEVSE>
                ))}
            </WithChargePoint>
        </Stack>
    );
}

interface FormData {
    chargePoint: string;
    connector: string;
    startTime: DateTime | null;
    endTime: DateTime | null;
    customer: string;
    energy: number;
}

function CustomerSelector() {
    const formik = useFormikContext<FormData>();
    const inputRef = useRef<HTMLInputElement>();

    const loading = CustomerSuggestionContext.useStore(store => store.loading);
    const setSearch = CustomerSuggestionContext.useStore(store => store.setSearch);
    const customersInfo = CustomerSuggestionContext.useStore(store => store.customers);

    const selectedValue = useMemo(
        () => customersInfo.find(item => item._id === formik.values.customer),
        [customersInfo, formik.values.customer],
    );

    return (
        <Stack spacing={1}>
            <FormControl fullWidth>
                <Autocomplete
                    sx={{ minWidth: '15em' }}
                    fullWidth
                    options={customersInfo}
                    noOptionsText="Клиент не найден"
                    value={selectedValue || null} //null is neede for initialization render in controlled state
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    getOptionLabel={option => option.phone}
                    renderOption={(props, option, state) => (
                        <li {...props} key={option._id}>
                            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                                {option.operator && (
                                    <DataWrap color={colors.grey[200]}>
                                        <OperatorName id={option.operator} />
                                    </DataWrap>
                                )}
                                <Typography>{option.phone}</Typography>
                            </Stack>
                        </li>
                    )}
                    onInputChange={(event, newValue) => setSearch(newValue)}
                    onChange={(event, val) => formik.setValues(values => ({ ...values, customer: val?._id || '' }))}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputRef={inputRef}
                            variant="outlined"
                            placeholder="+79990000000"
                            error={formik.touched.customer && Boolean(formik.errors.customer)}
                            helperText={formik.touched.customer && formik.errors.customer}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {selectedValue?.operator && (
                                            <DataWrap color={colors.grey[200]}>
                                                <OperatorName id={selectedValue.operator} />
                                            </DataWrap>
                                        )}
                                    </>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </FormControl>
        </Stack>
    );
}

function DialogInternal() {
    const hide = useManualTransactionSubmitDialog(store => store.hide);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: {
            chargePoint: '',
            connector: '',
            startTime: DateTime.now(),
            endTime: DateTime.now(),
            customer: '',
            energy: 0,
        },

        validationSchema: yup.object({
            chargePoint: yup.string().required('Charge point id must be provided'),
            connector: yup.string().required('Connector id must be provided'),
            startTime: yup
                .string()
                .required()
                .test(value => DateTime.fromISO(value!).isValid),
            customer: yup.string().required('Customer id must be provided'),
            energy: yup.number().required().positive().min(0),
        }),

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.manualCreateTransaction({
                ...values,
                //@ts-ignore
                startTime: values.startTime!.toUTC().toISO(),
                //@ts-ignore
                endTime: values.endTime!.toUTC().toISO(),
            });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    const chargePoints = useRegistry(store => store.chargePoints);
    const chargePointOptions = useMemo(() => {
        return Object.values(chargePoints).map(item => ({
            value: item._id,
            label: item.physicalReference,
        }));
    }, [chargePoints]);

    return (
        <>
            <DialogTitle>Создание транзакции</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 2 }}>
                    Добавление транзакции в историю зарядных сессий
                </DialogContentText>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <Stack direction={'column'} spacing={2}>
                                <Autocomplete
                                    id="chargePoints"
                                    fullWidth
                                    options={chargePointOptions}
                                    getOptionLabel={option => option.label}
                                    renderOption={(props, option, state) => (
                                        <li {...props} key={option.value}>
                                            {option.label}
                                        </li>
                                    )}
                                    // value={currentLocation || null} //null is needed for initialization render in controlled state
                                    onChange={(event, val) => {
                                        formik.setValues(values => ({ ...values, chargePoint: val?.value || '' }));
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="Зарядная станция"
                                            placeholder="Выберите зарядную станцию"
                                            error={formik.touched.chargePoint && Boolean(formik.errors.chargePoint)}
                                            helperText={formik.touched.chargePoint && formik.errors.chargePoint}
                                        />
                                    )}
                                />

                                <Stack direction={'column'} spacing={1}>
                                    <ListItemText primary="Коннекторы" />
                                    {formik.touched.connector && Boolean(formik.errors.connector) && (
                                        <FormHelperText
                                            error={formik.touched.connector && Boolean(formik.errors.connector)}
                                            sx={{ textAlign: 'center', color: '#d32f2f' }}
                                        >
                                            {formik.touched.connector && formik.errors.connector}
                                        </FormHelperText>
                                    )}
                                    <ConnectorsBlock />
                                </Stack>

                                <Stack direction={'column'} spacing={2}>
                                    <ListItemText primary="Детали транзакции" />

                                    <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
                                        <DateTimePicker
                                            ampm={false}
                                            ampmInClock={true}
                                            format="dd.MM.y - HH:mm"
                                            label="Начало"
                                            value={formik.values.startTime}
                                            onChange={newStartTime => {
                                                formik.setValues(values => ({ ...values, startTime: newStartTime }));
                                            }}
                                            slotProps={{
                                                textField: { fullWidth: true, required: true },
                                            }}
                                        />
                                        <DateTimePicker
                                            ampm={false}
                                            format="dd.MM.y - HH:mm"
                                            label="Завершение"
                                            value={formik.values.endTime}
                                            onChange={newEndTime => {
                                                formik.setValues(values => ({ ...values, endTime: newEndTime }));
                                            }}
                                            slotProps={{
                                                textField: { fullWidth: true, required: true },
                                            }}
                                        />
                                    </Stack>

                                    {/* <TextField
                                        id="idTag"
                                        label="ID Tag"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={formik.values.cus}
                                        onChange={formik.handleChange}
                                        error={formik.touched.idTag && Boolean(formik.errors.idTag)}
                                        helperText={formik.touched.idTag && formik.errors.idTag}
                                    /> */}
                                    <CustomerSuggestionContext.Provider
                                        createStore={() => createCustomerSuggestionStore()}
                                    >
                                        <CustomerSelector />
                                    </CustomerSuggestionContext.Provider>

                                    <TextField
                                        id="energy"
                                        label="Энергия"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={formik.values.energy}
                                        onChange={formik.handleChange}
                                        error={formik.touched.energy && Boolean(formik.errors.energy)}
                                        helperText={formik.touched.energy && formik.errors.energy}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Вт⋅ч</InputAdornment>,
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </form>
                    </FormikProvider>
                </LocalizationProvider>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Создать</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

export function ManualTransactionSubmitDialog() {
    const open = useManualTransactionSubmitDialog(store => store.open);
    const hide = useManualTransactionSubmitDialog(store => store.hide);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            // open={true}
            onClose={hide}
            maxWidth={'sm'}
            fullScreen={fullScreen}
            fullWidth
        >
            <DialogInternal />
        </Dialog>
    );
}
