import * as yup from 'yup';
import { ChargePoint } from '../../data/infrastructure/ChargeLocation';

export type ReqParams = { id: string };
export type ReqBody = {
    physicalReference?: string;
    vendor?: string;
    model?: string;
    serialNumber?: string;
    simNumber?: string;
    publish?: boolean;
    freevend?: boolean;
    ignorePingPong?: boolean;
    authorizationRequired?: boolean;
    comment?: string;
};

export type ResData = ChargePoint;

export const validationSchema = yup.object({
    physicalReference: yup.string().optional(),
    vendor: yup.string().optional(),
    model: yup.string().optional(),
    serialNumber: yup.string().optional(),
    simNumber: yup.string().optional(),
    publish: yup.boolean().optional(),
    freevend: yup.boolean().optional(),
    ignorePingPong: yup.boolean().optional(),
    authorizationRequired: yup.boolean().optional(),
    comment: yup.string().optional(),
    infoMessage: yup.string().optional(),
});
