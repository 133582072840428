import { Box, Button, Stack, Tab, Tabs } from '@mui/material';

import { Title } from '../../elements/Title';
import {RouterLink} from "../../elements/RouterLink";
import React, { useState } from "react";
import {useRouteMatch} from "../../hooks/useRouteMatch";
import {Outlet, useParams } from 'react-router-dom';

export function MorphPhotosListTopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([`/${operatorId}/photos/`, `/${operatorId}/photos/processed`]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} variant="scrollable" scrollButtons="auto">
                <Tab label="Не обработанные" value={`/${operatorId}/photos/`} component={RouterLink} to="" />
                <Tab label="Обработанные" value={`/${operatorId}/photos/processed`} component={RouterLink} to="processed" />
            </Tabs>
        </Box>
    );
}

export function MorphPhotosListLayout() {
    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Фотографии</Title>
            </Stack>

            <MorphPhotosListTopMenu />
            <Outlet />
        </>
    );
}
