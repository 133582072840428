import { Box } from '@mui/material';
import {useChargeLocation} from "../../wrappers/WithChargeLocation";
import MorphPhotosListTableComponent from "./MorphPhotosListTableComponent";
import {TransactionListTableComponent} from "../../transaction/components/list-table/TransactionListTableComponent";

export default function ChargeLocationMorphPhotosHistoryPage() {
    const chargeLocation = useChargeLocation();

    return (
        <Box sx={{ marginTop: 2 }}>
            <MorphPhotosListTableComponent filter={{ locationId: chargeLocation._id, showProcessed: '1' }} />
        </Box>
    );
}

export function MorphPhotosListPageProcessed() {
    return (
        <Box sx={{ marginTop: 2 }}>
            <MorphPhotosListTableComponent filter={{ showProcessed: '0' }} />
        </Box>
    );
}

export function MorphPhotosListPageAll() {
    return (
        <Box sx={{ marginTop: 2 }}>
            <MorphPhotosListTableComponent filter={{ showProcessed: '1' }} />
        </Box>
    );
}