import {
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import {ActionEventListPageService, Options} from "../../event-log/components/table/ListPageService";
import React, { PropsWithChildren } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import {MorphPhotoService} from "../services/MorphPhotoService";
import {useOperatorId} from "../../../hooks/useOperatorId";
import {HumanDate} from "../../mixin/HumanDate";
import { ReviewsList } from '@electrifly/central-client-api';
import {RouterLink} from "../../../elements/RouterLink";

interface MorphPhotoItemProps {
    photo: any
    locationId?: string
}

function MorphPhotoItem({photo, locationId}: MorphPhotoItemProps) {
    const navigate = useNavigate();
    const operatorId = useOperatorId()
    const toggleStatus = MorphPhotoService.useStore(store => store.toggleApprovedStatus);
    const toggleSavedAndHiddenStatus = MorphPhotoService.useStore(store => store.toggleSavedAndHiddenStatus);

    return (
        <TableRow
            key={photo._id}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
            <TableCell>

                <RouterLink to={`/${operatorId}/locations/${photo.entityId}/photos`}>
                    { photo.entityName }
                </RouterLink>
                <br/>
                <br/>
                <RouterLink to={`/${operatorId}/customers/${photo.account}`}>
                    { photo.accountName }
                </RouterLink>
            </TableCell>
            <TableCell>
                <img onClick={() => window.open(photo.photoUrl, "_blank")} height={145} width={200} src={photo.photoUrl}/>
            </TableCell>
            <TableCell>
                <HumanDate time={photo.created}/>
            </TableCell>
            <TableCell>
                <Grid
                    container
                    direction="column"
                    sx={{
                        justifyContent: "center",
                        alignItems: "flex-start",
                    }}
                >
                    <Button onClick={() => toggleStatus(photo._id)} variant="contained" color={photo.isApproved ? 'secondary' : 'warning'}>
                        { photo.isApproved ? 'Запретить' : 'Разрешить' }
                    </Button>

                    {
                        !locationId && (
                            <Button style={{marginTop: 15}} onClick={() => toggleSavedAndHiddenStatus(photo._id)}
                                    variant="contained" color={photo.isSavedAndHidden ? 'warning' : 'success'}>
                                {'Сохранить и скрыть'}
                            </Button>
                        )
                    }
                </Grid>
            </TableCell>
        </TableRow>
    )
}

interface ChargeLocationTableProps {
    locationId?: string
}
function MorphPhotosTable({ locationId }: ChargeLocationTableProps) {
    const photos = MorphPhotoService.useStore(store => store.photos);

    // const useLocations = useChargeLocation()

    // console.log(useLocations);

    // console.log(photos);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Адрес/Клиент</TableCell>
                        <TableCell>Фотография</TableCell>
                        <TableCell>Дата</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        photos.map((photo, key) => (
                            <MorphPhotoItem locationId={locationId} photo={photo} key={key}></MorphPhotoItem>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export interface Filter {
    operator?: string;
    holder?: string;
}

interface Props {
    filter?: Filter;
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = MorphPhotoService.useStore(store => store.loadNext);
    const reset = MorphPhotoService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>
}

interface ActionEventListTableProps {
    filter?: ReviewsList.ReqQuery
    options?: Partial<Options>;
}

export default function MorphPhotosListTableComponent({ filter = {}, options = {} }: ActionEventListTableProps) {
    // const filteredLocationIds = useFilteredLocationIds(filter);

    return <MorphPhotoService.Provider createStore={() => MorphPhotoService.createStore(filter, {})}>
        <LoadWrapper>
            <MorphPhotosTable locationId={filter.locationId}/>
        </LoadWrapper>
    </MorphPhotoService.Provider>
}
