import {AccessScope} from '@electrifly/central-client-api';
import StarBorder from '@mui/icons-material/StarBorder';
import {
    Collapse,
    createTheme,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListSubheader,
    ThemeProvider,
} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import React, {useEffect, useState} from 'react';
import {PropsWithChildren, useMemo} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {WithAccessScope} from '../components/wrappers/WithAccessScope';
import {Logo} from '../elements/Logo';
import {useDrawerStore} from '../stores/DrawerStore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {useRouteMatch} from '../hooks/useRouteMatch';
import {ElectriflyTheme} from '../theme/ElectriflyTheme';
import {API} from "../core/api-client";

export const SIDEBAR_WIDTH = 240;

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

type NavigationItemProps = {
    title: string;
    url: string;
    nested?: boolean;
};

const UpdateGarageTemporaryButton = () => {
    const [isLoading, setIsLoading] = useState(false)

    const updateGarageAction = async () => {
        setIsLoading(true)

        const [error, res] = await API.updateGarageAction();

        console.log(error);
        console.log(res?.data);

        setIsLoading(false)
    }

    return (
        <ListItemButton onClick={() => updateGarageAction()} title={isLoading ? 'Ждите...' : 'Обновить гараж'}/>
    )
}

const NavigationItem = ({title, url, nested = false, children}: PropsWithChildren<NavigationItemProps>) => {
    const {pathname} = useLocation();
    const selected = useMemo(() => pathname.startsWith(url), [url, pathname]);
    const [open, setOpen] = React.useState(selected);
    const closeDrawer = useDrawerStore(store => store.close);

    useEffect(() => setOpen(selected), [selected]);

    const handleClick = (event: React.MouseEvent) => {
        setOpen(!open);
        event.stopPropagation();
        event.preventDefault();
    };

    return (
        <>
            <ListItemButton dense={true} selected={selected} component={Link} to={url} onClick={() => closeDrawer()}>
                <ListItemText primary={title} sx={{paddingLeft: nested ? 2 : 0}}/>

                {children && (
                    <ListItemSecondaryAction sx={{alignItems: 'center', justifyContent: 'center'}}>
                        <IconButton edge="end" sx={{padding: 0.5}} onClick={event => handleClick(event)}>
                            {open ? <ExpandLess/> : <ExpandMore/>}
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItemButton>
            {children && (
                <Collapse in={open} timeout="auto">
                    {children}
                </Collapse>
            )}
        </>
    );
};

const DrawerInternal = () => {
    return (
        <>
            <Toolbar disableGutters>
                <Logo/>
            </Toolbar>
            <Divider/>
            <Box sx={{overflow: 'auto'}}>
                <List subheader={<ListSubheader sx={{textTransform: 'uppercase'}}>CPO</ListSubheader>}>
                    <NavigationItem title="Панель управления" url="dashboard"/>
                    <NavigationItem title="Лог событий" url="events"/>
                    <NavigationItem title="Зарядные локации" url="locations"/>
                    <NavigationItem title="Фотографии" url="photos"/>
                    <NavigationItem title="Отзывы" url="reviews"/>
                    <NavigationItem title="Шлагбаумы" url="gates"/>
                </List>

                <Divider/>

                <WithAccessScope scope={AccessScope.OPERATOR}>
                    <List subheader={<ListSubheader sx={{textTransform: 'uppercase'}}>EMSP</ListSubheader>}>
                        <NavigationItem title="Клиенты" url="customers"/>
                        <NavigationItem title="Корпоративные клиенты" url="corporate"/>
                        <NavigationItem title="Зарядные метки" url="tags"/>
                        <NavigationItem title="Биллинг" url="billings"/>
                    </List>
                </WithAccessScope>

                <Divider/>

                <List subheader={<ListSubheader sx={{textTransform: 'uppercase'}}>EMSP – CPO</ListSubheader>}>
                    <NavigationItem title="Транзакции" url="transactions"/>
                    <NavigationItem title="Статистика" url="stats"/>
                    <NavigationItem title="Доступность" url="uptime"/>
                    {/* <NavigationItem title="Отчёты" url="reports" /> */}
                </List>

                <Divider/>

                <List subheader={<ListSubheader sx={{textTransform: 'uppercase'}}>Administrative</ListSubheader>}>
                    <NavigationItem title="Администраторы" url="users"/>
                    <WithAccessScope scope={AccessScope.OPERATOR}>
                        <NavigationItem title="Владельцы станций" url="holders"/>
                        <NavigationItem title="Роуминг" url="roaming"/>
                        <NavigationItem title="Интеграции" url="integration"/>
                        <NavigationItem title="Специальные функции" url="special"/>
                    </WithAccessScope>
                </List>

                <Divider/>
                <WithAccessScope scope={AccessScope.GLOBAL}>
                    <List subheader={<ListSubheader sx={{textTransform: 'uppercase'}}>Global</ListSubheader>}>
                        <NavigationItem title="Операторы" url="operators"/>
                    </List>

                    <UpdateGarageTemporaryButton/>
                </WithAccessScope>
            </Box>
        </>
    );
};

export default function Sidebar() {
    const isOpen = useDrawerStore(store => store.isOpen);
    const toggle = useDrawerStore(store => store.toggle);

    return (
        <>
            <Drawer
                // container={container}
                variant="temporary"
                open={isOpen}
                onClose={toggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                    sx: {width: SIDEBAR_WIDTH, boxSizing: 'border-box'},
                }}
                sx={{
                    display: {xs: 'block', md: 'none'},
                }}
            >
                <DrawerInternal/>
            </Drawer>
            <ThemeProvider theme={darkTheme}>
                <Drawer
                    variant="permanent"
                    PaperProps={{
                        sx: {width: SIDEBAR_WIDTH, boxSizing: 'border-box'},
                    }}
                    sx={{
                        width: SIDEBAR_WIDTH,
                        flexShrink: 0,
                        display: {xs: 'none', md: 'block'},
                    }}
                    open
                >
                    <DrawerInternal/>
                </Drawer>
            </ThemeProvider>
        </>
    );
}
