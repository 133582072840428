import * as yup from 'yup';
import { Transaction } from '../../data/transaction/Transaction';

export interface Filter {
    rangeStart?: string;
    rangeEnd?: string;
    operators?: string[];
    holders?: string[];
    locations?: string[];
    chargePoints?: string[];
    owners?: string[];
    ownerAccounts?: string[];
}

export interface Format {
    columns: keyof RowType[];
    raw?: boolean;
}

export type ReqBody = {
    filter: Filter;
    format: Format;
};

export type ResData = Partial<RowType>[];

export const filterSchema = yup.object({
    rangeStart: yup
        .string()
        .required()
        .test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
    rangeEnd: yup
        .date()
        .required()
        .test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
    operators: yup.array().of(yup.string()).optional(),
    holders: yup.array().of(yup.string()).optional(),
    locations: yup.array().of(yup.string()).optional(),
    chargePoints: yup.array().of(yup.string()).optional(),
    owners: yup.array().of(yup.string()).optional(),
    ownerAccounts: yup.array().of(yup.string()).optional(),
});

export const formatSchema = yup.object({
    columns: yup.array().of(yup.string()).default([]),
    raw: yup.boolean().optional().default(false),
});

export const validationSchema = yup.object({
    filter: filterSchema,
    format: formatSchema,
});

export interface RowType {
    humanId: number; // transaction id
    _id: string; // system  _id
    chargeLocationId: string;
    chargeLocationName: string;
    chargePointId: string;
    roamingIdentity: string;
    physicalReference: string;
    chargePointVendor: string;
    chargePointModel: string;
    chargePointSerialNumber: string;
    address: string;
    latitude: number | string;
    longitude: number | string;
    connectorNumber: number;
    connectorType: string;
    connectorPower: number;
    startTime: string;
    endTime: string;
    stationStartTime: string;
    stationEndTime: string;
    durationSec: number; // duration in seconds
    durationMin: number; // duration in minutes
    socStart: number;
    socStop: number;
    powerActiveMax: number;
    energy: number; // energy consumption
    energyKWh: number; // energy consumption kWh
    stopReason: string;
    tariff: number | string; // total customer cost consumption,
    cost: number | string; // total customer cost consumption,
    moneyAfterAcquring: number | string; // money flow to Electrifly after acquring
    tokenType: string;
    tokenVariant: string;
    owner: string;
    ownerId: string;
    ownerEmail: string;
    emspCustomerId: string;
    accountOwnerType: string;
    account: string;
    accountId: string;
    isOperator: boolean;
    operator: string;
    holder: string;
}

interface Column {
    header: string;
    key: keyof RowType;
    selected?: boolean; // default selected state
}

export const COLUMNS: Record<keyof RowType, Column> = {
    humanId: { header: 'ID транзакции', key: 'humanId' },
    _id: { header: 'ID транзакции (system)', key: '_id' },
    chargeLocationId: { header: 'ID локации', key: 'chargeLocationId', selected: false },
    chargeLocationName: { header: 'Название локации', key: 'chargeLocationName' },
    chargePointId: { header: 'ID станции', key: 'chargePointId', selected: false },
    roamingIdentity: { header: 'ID станции роуминг', key: 'roamingIdentity', selected: false },
    physicalReference: { header: 'Название станции', key: 'physicalReference' },
    chargePointVendor: { header: 'Производитель станции', key: 'chargePointVendor', selected: false },
    chargePointModel: { header: 'Модель станции', key: 'chargePointModel', selected: false },
    chargePointSerialNumber: { header: 'Серийный номер', key: 'chargePointSerialNumber', selected: false },
    address: { header: 'Адрес станции', key: 'address' },
    latitude: { header: 'Широта', key: 'latitude' },
    longitude: { header: 'Долгота', key: 'longitude' },
    connectorNumber: { header: 'Номер коннектора', key: 'connectorNumber' },
    connectorType: { header: 'Тип коннектора', key: 'connectorType' },
    connectorPower: { header: 'Мощность коннектора | кВт', key: 'connectorPower' },
    startTime: { header: 'Время старта', key: 'startTime' }, // UTC
    endTime: { header: 'Время завершения', key: 'endTime' }, // UTC
    stationStartTime: { header: 'Время старта на станции', key: 'stationStartTime', selected: false }, // UTC
    stationEndTime: { header: 'Время завершения на станции', key: 'stationEndTime', selected: false }, // UTC
    durationSec: { header: 'Длительность | секунды', key: 'durationSec' },
    durationMin: { header: 'Длительность | минуты', key: 'durationMin' },
    socStart: { header: 'SoC старт', key: 'socStart' },
    socStop: { header: 'SoC завершение', key: 'socStop' },
    powerActiveMax: { header: 'Пиковая мощность | кВт', key: 'powerActiveMax' },
    energy: { header: 'Потребление | Вт⋅ч', key: 'energy', selected: false },
    energyKWh: { header: 'Потребление | кВт⋅ч', key: 'energyKWh' },
    stopReason: { header: 'Причина остановки', key: 'stopReason' },
    tariff: { header: 'Тариф ₽/кВт⋅ч', key: 'tariff' },
    cost: { header: 'Стоимость по тарифу | ₽', key: 'cost' },
    moneyAfterAcquring: { header: 'Итого за вычетом эквайринга | ₽', key: 'moneyAfterAcquring' },
    tokenType: { header: 'Тип авторизации', key: 'tokenType' },
    tokenVariant: { header: 'Тип клиента', key: 'tokenVariant' },
    owner: { header: 'Клиент', key: 'owner', selected: false },
    ownerEmail: { header: 'Email клиента', key: 'ownerEmail', selected: false },
    ownerId: { header: 'ID клиента CPO', key: 'ownerId', selected: false },
    emspCustomerId: { header: 'ID клиента eMSP', key: 'emspCustomerId', selected: false },
    accountOwnerType: { header: 'Тип аккаунта', key: 'accountOwnerType' },
    account: { header: 'Аккаунт', key: 'account', selected: false },
    accountId: { header: 'ID аккаунта', key: 'accountId', selected: false },
    isOperator: { header: 'Запуск оператором', key: 'isOperator', selected: false },
    operator: { header: 'Оператор', key: 'operator', selected: false },
    holder: { header: 'Владелец', key: 'holder', selected: false },
};
