import {
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { Options} from "../../event-log/components/table/ListPageService";
import React, { PropsWithChildren } from 'react';
import {useOperatorId} from "../../../hooks/useOperatorId";
import {GateService} from "../services/GateService";
import { useEffectOnce } from 'usehooks-ts';

function GateItem({gate}: any) {
    const navigate = useNavigate();
    const operatorId = useOperatorId()
    // const toggleStatus = MorphPhotoService.useStore(store => store.toggleApprovedStatus);

    return (
        <TableRow
            key={gate._id}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
            <TableCell onClick={() => navigate(`/${operatorId}/locations/${gate.location}`)}>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                    <Typography fontSize={'inherit'} sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        { gate.locationName }
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell onClick={() => navigate(`/${operatorId}/customers/${gate.account}`)}>
                {gate.accountName }
            </TableCell>
            <TableCell>
                { gate.ticket }
            </TableCell>
            <TableCell>
                {gate.created }
            </TableCell>
            <TableCell>
                {gate.expiresAt }
            </TableCell>
            {/*<TableCell>*/}
            {/*    <OperatorLink id={location.operator} />*/}
            {/*</TableCell>*/}
            {/*<TableCell>*/}
            {/*    <HolderLink id={location.holder} />*/}
            {/*</TableCell>*/}
        </TableRow>
    );
}

function GatesTable() {
    const gates = GateService.useStore(store => store.gates);

    // const useLocations = useChargeLocation()

    // console.log(useLocations);

    // console.log(photos);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Адрес</TableCell>
                        <TableCell>Клиент</TableCell>
                        <TableCell>ID билета</TableCell>
                        <TableCell>Дата создания</TableCell>
                        <TableCell>Дата окончания</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        gates.map((gate, key) => (
                            <GateItem gate={gate} key={key}></GateItem>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export interface Filter {
    operator?: string;
    holder?: string;
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = GateService.useStore(store => store.loadNext);
    const reset = GateService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface ActionEventListTableProps {
    filter?: Partial<Filter>;
    options?: Partial<Options>;
}

export default function GatesListTableComponent({ filter = {}, options = {} }: ActionEventListTableProps) {
    return (
        <GateService.Provider  createStore={() => GateService.createStore({}, {})}>
            <LoadWrapper>
                <GatesTable/>
            </LoadWrapper>
        </GateService.Provider>
    )
}
