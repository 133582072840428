import { Link } from '@mui/material';
import { useEffect } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { RouterLink } from '../../../elements/RouterLink';
import { useDataCache } from '../../../services/DataCache';
import { useParams } from 'react-router-dom';

interface HolderLinkProps {
    id: string;
}

export function HolderLink({ id }: HolderLinkProps) {
    const loadHolder = useDataCache(store => store.loadHolder);
    const holder = useDataCache(store => store.holders[id]);
    const { operatorId } = useParams();

    useEffect(() => {
        loadHolder(id);
    }, [id, loadHolder]);

    return (
        <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/${operatorId}/holders/${id}`}
            onClick={event => event.stopPropagation()}
        >
            {holder?.identity || id}
        </Link>
    );
}
