import {
    Stack,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
    Button,
    Alert,
    AlertTitle,
    Paper,
    Box,
    ListItemIcon,
    Badge,
    Divider,
    Collapse,
    Link,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BatteryCharging50RoundedIcon from '@mui/icons-material/BatteryCharging50Rounded';
import { TransitionGroup } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import { useRoamingLocation } from '../../wrappers/WithRoamingLocation';
import { RouterLink } from '../../../elements/RouterLink';
import { RoamingConnector, RoamingEVSE, RoamingLocation, Transaction } from '@electrifly/central-client-api';
import { RoamingEVSEStatus } from '../elements/RoamingEVSEStatus';
import { ConnectorTypeText } from '../../connector/Standard';
import { TransactionEnergyKWh } from '../../transaction/elements/TransactionEnergy';
import { useChargePointRemoteStopDialog } from '../../charge-point/dialogs/ChargePointRemoteStop';
import { useMemo } from 'react';
import moment from 'moment';
import { useTransactionByRoamingConnector, useTransactionIdsByConnector } from '../../../hooks/chargePointHooks';

type TransactionBlockProps = {
    transaction: Transaction;
};
function TransactionBlock({ transaction }: TransactionBlockProps) {
    const { operatorId } = useParams();
    const showRemoteStopDialog = useChargePointRemoteStopDialog(store => store.show);
    const startTime = useMemo(() => {
        return moment(transaction.startTime).format('DD.MM HH:mm:ss');
    }, [transaction.startTime]);

    return (
        <ListItem dense>
            <ListItemIcon sx={{ justifyContent: 'center' }}>
                <BatteryCharging50RoundedIcon color="status.charging" />
            </ListItemIcon>
            <ListItemText
                primaryTypographyProps={{ component: 'div' }}
                primary={
                    <Link
                        component={RouterLink}
                        to={`/${operatorId}/transactions/${transaction.transactionId}`}
                        sx={{ textDecoration: 'none', ':hover': { textDecoration: 'underline' } }}
                        color="inherit"
                    >
                        Транзакция #{transaction.transactionId}
                    </Link>
                }
                secondaryTypographyProps={{ component: 'div' }}
                secondary={
                    <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                        <Typography fontSize={'inherit'}>{startTime}</Typography>
                        <Typography fontSize={'inherit'}>
                            <TransactionEnergyKWh energy={transaction.meterDiff} unit={'Wh'} />
                        </Typography>
                        <Typography fontSize={'inherit'}>{transaction.totalCost} ₽</Typography>
                    </Stack>
                }
            />

            <ListItemSecondaryAction>
                <Button
                    variant="text"
                    // size="small"
                    color={'error'}
                    // disabled={!chargePointOnline}
                    onClick={() => showRemoteStopDialog(transaction.transactionId)}
                >
                    Остановить
                </Button>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

interface ConnectionBlockProps {
    location: RoamingLocation;
    evse: RoamingEVSE;
    evseSeqNumber: number;
    connector: RoamingConnector;
    connectorSeqNumber: number;
}
function ConnectorBlock({ location, evse, connector, evseSeqNumber, connectorSeqNumber }: ConnectionBlockProps) {
    const transactions = useTransactionByRoamingConnector(connector);
    const hasActiveTransactions = useMemo(() => transactions.length > 0, [transactions]);

    return (
        <List component={Paper} key={connector._id} disablePadding>
            <ListItem
                divider={hasActiveTransactions}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    // backgroundColor: connector.statusMessage.status === 'Faulted' ? 'rgba(255, 0,0,0.05)' : '',
                }}
            >
                <ListItemIcon>
                    <Badge
                        badgeContent={
                            <Stack direction={'row'} divider={<Box>:</Box>}>
                                <Box>{evseSeqNumber}</Box>
                                <Box>{connectorSeqNumber}</Box>
                            </Stack>
                        }
                        color={`ocpi.status.${evse.status.toLowerCase()}` as any}
                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                        <RoamingEVSEStatus status={evse.status} fontSize="large" />
                    </Badge>
                </ListItemIcon>

                <ListItemText
                    primaryTypographyProps={{ component: 'div' }}
                    primary={
                        <Stack direction={'row'} spacing={1}>
                            <Typography>{evse.status}</Typography>
                            {/* {connector.isTurnedOff && <ConnectorTurnedOffChip />} */}
                        </Stack>
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                    secondary={
                        <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                            <Typography component="span">
                                <ConnectorTypeText type={connector.standard} />
                            </Typography>
                            <Typography component="span">{connector.maxElectricPower} кВт</Typography>
                            <Typography component="span">{connector.powerType}</Typography>
                        </Stack>
                    }
                />

                {/* <ListItemSecondaryAction>
                    <ConnectorRightMenu />
                </ListItemSecondaryAction> */}
            </ListItem>

            {/* {connector.statusMessage.status === 'Faulted' && (
                <ListItem
                    dense
                    sx={{
                        pl: 4,
                        flexWrap: 'wrap',
                        backgroundColor: 'rgba(255, 0, 0, 0.05)',
                    }}
                >
                    <Chip
                        sx={{ m: 0.5 }}
                        size={'small'}
                        color="error"
                        label={`errorCode: ${connector.statusMessage.errorCode}`}
                    />
                    {connector.statusMessage.vendorId && (
                        <Chip
                            sx={{ m: 0.5 }}
                            size={'small'}
                            color="error"
                            label={`vendorId: ${connector.statusMessage.vendorId}`}
                        />
                    )}
                    {connector.statusMessage.vendorErrorCode && (
                        <Chip
                            sx={{ m: 0.5 }}
                            size={'small'}
                            color="error"
                            label={`vendorErrorCode: ${connector.statusMessage.vendorErrorCode}`}
                        />
                    )}
                    {connector.statusMessage.info && (
                        <Chip
                            sx={{ m: 0.5 }}
                            size={'small'}
                            color="error"
                            label={`info: ${connector.statusMessage.info}`}
                        />
                    )}
                </ListItem>
            )} */}

            <TransitionGroup>
                {transactions.map(transaction => (
                    <Collapse key={transaction._id}>
                        <TransactionBlock key={transaction._id} transaction={transaction} />
                    </Collapse>
                ))}
            </TransitionGroup>
        </List>
    );
}

function PageInternal() {
    const location = useRoamingLocation();
    const { operatorId } = useParams();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Коннекторы</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Stack>
            </Grid>
            <Grid item lg={6} xs={12}>
                <Stack spacing={2}>
                    {location.evses.map((evse, evseIndex) => (
                        <Box key={evse._id}>
                            {evse.connectors.map((connector, connectorIndex) => (
                                <ConnectorBlock
                                    key={connector._id}
                                    location={location}
                                    evse={evse}
                                    evseSeqNumber={evseIndex + 1}
                                    connector={connector}
                                    connectorSeqNumber={connectorIndex + 1}
                                />
                            ))}
                        </Box>
                    ))}
                </Stack>
            </Grid>
        </Grid>
    );
}

export function RoamingLocationDashboardPage() {
    return <PageInternal />;
}
