import { EventLog, EventLogType, ReviewsList } from '@electrifly/central-client-api';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import {API} from "../../../core/api-client";
import {createWithImmer} from "../../../misc/CreateWithImmer";
import {ColumnKey} from "../../event-log/components/table/TableColumns";


export type FilterUIType = 'eventlog' | 'chargepoint' | 'transaction' | 'customer' | 'date';

export type Options = Record<ColumnKey, boolean>;

type Data = {
    photos: EventLog[];
    loading: boolean;
    canLoadMore: boolean;

    filter: ReviewsList.ReqQuery;
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
    toggleApprovedStatus: (photoId: string) => Promise<void>
    toggleSavedAndHiddenStatus: (photoId: string) => Promise<void>
};

type Service = Data & Actions;

const DEFAULT_FILTER: ReviewsList.ReqQuery = {
    limit: 100,
};

function createDefaultData(): Data {
    return {
        photos: [],
        loading: false,
        canLoadMore: false,

        filter: DEFAULT_FILTER,
    };
}

const createStore = (filter?: ReviewsList.ReqQuery, options?: Partial<Options> | undefined) => {
    const initialData = {
        ...createDefaultData(),
        filter: { ...createDefaultData().filter, ...filter },
    };

    return createWithImmer<Service>((set, get) => {
        // WebsocketClient.events.ACTION_EVENT.on(actionEvent => {
        //     const { events, filter } = get();
        //     const passed = transactionPassFilter(actionEvent, filter);
        //     if (!passed) {
        //         set(draft => {
        //             const index = draft.events.findIndex(item => item._id === actionEvent._id);
        //             if (index !== -1) {
        //                 draft.events.splice(index, 1);
        //             }
        //         });
        //
        //         return;
        //     }
        // });

        function resetData() {
            set({ photos: [] });
        }

        async function toggleApprovedStatus (photoId: string) {
            const [error, res] = await API.togglePhotoApproveStatus(photoId)

            if(error) {
                console.log(error);
                return
            }

            // If there`s a global list page we just need remove it from there.
            // if(!filter?.locationId) {
            //     set({photos: get().photos.filter(e => e._id !== res.data._id)})
            //     return;
            // }

            const newPhotos = get().photos.map(e => {
                if(e._id == res.data._id) {
                    return {
                        ...e,
                        isApproved: res.data.isApproved,
                        isSavedAndHidden: res.data.isSavedAndHidden,
                    }
                }
                return {
                    ...e,
                }
            })

            set({photos: newPhotos})
        }

        async function toggleSavedAndHiddenStatus (photoId: string) {
            const [error, res] = await API.togglePhotoSavedAndHiddenStatus(photoId)

            if(error) {
                console.log(error);
                return
            }

            // If there`s a global list page we just need remove it from there.
            // if(!filter?.locationId) {
            //     set({photos: get().photos.filter(e => e._id !== res.data._id)})
            //     return;
            // }

            const newPhotos = get().photos.map(e => {
                if(e._id == res.data._id) {
                    return {
                        ...e,
                        isApproved: res.data.isApproved,
                    }
                }
                return {
                    ...e,
                }
            })

            set({photos: newPhotos})
        }

        async function loadNext() {
            if (get().loading) {
                debouncedLoadNext();
                return;
            }

            set({ loading: true });

            const { filter, photos } = get();

            const skip = photos.length;
            const [error, res] = await API.photosList({
                skip,
                limit: filter.limit,
                locationId: filter.locationId,
                showProcessed: filter.showProcessed,
            });

            if (error) {
                console.error(error);
                set({ loading: false });
                return;
            }

            const newData = res.data;
            const canLoadMore = newData.length === filter.limit;

            set({
                loading: false,
                canLoadMore: canLoadMore,
                photos: [...get().photos, ...newData],
            });
        }

        function loadNexWithReset() {
            resetData();
            loadNext();
        }

        const debouncedLoadNext = _.debounce(() => loadNext(), 300);
        const debouncedLoadNextWithReset = _.debounce(() => loadNexWithReset(), 300);

        return {
            ...initialData,
            reset: () => set({ ...initialData }),
            loadNext,
            toggleApprovedStatus,
            toggleSavedAndHiddenStatus
        };
    });
};

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const MorphPhotoService = {
    Provider,
    createStore,
    useStore,
};
