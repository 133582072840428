import {
    OperatorNotification,
    OperatorNotificationDefaults,
    OperatorNotifictionType,
} from '@electrifly/central-client-api';
import { Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Typography } from '@mui/material';
import { useMemo } from 'react';
import { EditableValue } from '../../editable-values/EditableValue';
import { HolderLink } from '../../holders/elements/HolderLink';
import { useOperator } from '../../wrappers/WithOperator';
import { OperatorNotificationComponent } from '../components/OperatorNotificationBlock';
import { useOperatorDetailsPageService } from './services/PageService';

function NotificationsBlock() {
    const operator = useOperator();
    const updateNotificationState = useOperatorDetailsPageService(store => store.updateNotificationState);
    const addEmailToNotification = useOperatorDetailsPageService(store => store.addEmailToNotification);
    const removeEmailFromNotification = useOperatorDetailsPageService(store => store.removeEmailFromNotification);

    const mapped = useMemo<Record<OperatorNotifictionType, OperatorNotification>>(() => {
        return {
            [OperatorNotifictionType.ChargePointOffline]:
                operator.notifications.find(item => item.type === OperatorNotifictionType.ChargePointOffline) ||
                OperatorNotificationDefaults[OperatorNotifictionType.ChargePointOffline],

            [OperatorNotifictionType.MonthlyReport]:
                operator.notifications.find(item => item.type === OperatorNotifictionType.MonthlyReport) ||
                OperatorNotificationDefaults[OperatorNotifictionType.MonthlyReport],
        };
    }, [operator.notifications]);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Нотификации</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <OperatorNotificationComponent
                        data={mapped[OperatorNotifictionType.ChargePointOffline]}
                        onChangeActive={value =>
                            updateNotificationState({
                                type: OperatorNotifictionType.ChargePointOffline,
                                value: value,
                            })
                        }
                        onAddEmail={email =>
                            addEmailToNotification({ type: OperatorNotifictionType.ChargePointOffline, email: email })
                        }
                        onRemoveEmail={email =>
                            removeEmailFromNotification({
                                type: OperatorNotifictionType.ChargePointOffline,
                                email: email,
                            })
                        }
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <OperatorNotificationComponent
                        data={mapped[OperatorNotifictionType.MonthlyReport]}
                        onChangeActive={value =>
                            updateNotificationState({
                                type: OperatorNotifictionType.MonthlyReport,
                                value: value,
                            })
                        }
                        onAddEmail={email =>
                            addEmailToNotification({ type: OperatorNotifictionType.MonthlyReport, email: email })
                        }
                        onRemoveEmail={email =>
                            removeEmailFromNotification({
                                type: OperatorNotifictionType.MonthlyReport,
                                email: email,
                            })
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default function OperatorDetailsPage() {
    const operator = useOperator();
    const updateOperator = useOperatorDetailsPageService(store => store.update);

    return (
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Информация</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <List component={Paper} disablePadding>
                    <ListItem divider>
                        <ListItemText primary="Название" />
                        <ListItemSecondaryAction>
                            <EditableValue
                                value={operator.name}
                                onNewValue={newValue => updateOperator({ name: newValue })}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem divider>
                        <ListItemText primary="Страна" />
                        <ListItemSecondaryAction>
                            <ListItemText primary={operator.country} />
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem divider>
                        <ListItemText primary="Идентификатор" />
                        <ListItemSecondaryAction>
                            <ListItemText primary={operator.partyId} />
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Владелец станций по умолчанию" />
                        <ListItemSecondaryAction>
                            <HolderLink id={operator.defaultHolder} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>

            <Grid item lg={6} xs={12}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Информация</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <List component={Paper} disablePadding>
                    <ListItem divider>
                        <ListItemText primary="Mobile App Bundle ID" />
                        <ListItemSecondaryAction>
                            <EditableValue
                                value={operator.emspMobileId || ''}
                                onNewValue={newValue => updateOperator({ emspMobileId: newValue })}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Website" />
                        <ListItemSecondaryAction>
                            <EditableValue
                                value={operator.publicWebsite || ''}
                                onNewValue={newValue => updateOperator({ publicWebsite: newValue })}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Валюта (ISO-4217)" />
                        <ListItemSecondaryAction>
                            <EditableValue
                                value={operator.currency}
                                onNewValue={newValue => updateOperator({ currency: newValue })}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>

            <Grid item xs={12}>
                <NotificationsBlock />
            </Grid>
        </Grid>
    );
}
