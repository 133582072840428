import { Box } from '@mui/material';
import {useChargeLocation} from "../../wrappers/WithChargeLocation";
import ReviewsListTableComponent from "./ReviewsListTableComponent";
import MorphPhotosListTableComponent from "../../morph-photos/components/MorphPhotosListTableComponent";

export default function ChargeLocationReviewHistoryPage() {
    const chargeLocation = useChargeLocation();

    return (
        <Box sx={{ marginTop: 2 }}>
            <ReviewsListTableComponent filter={{ locationId: chargeLocation._id }} />
        </Box>
    );
}


export function ChargeLocationReviewListPageProcessed() {
    return (
        <Box sx={{ marginTop: 2 }}>
            <ReviewsListTableComponent filter={{ showProcessed: '0' }} />
        </Box>
    );
}

export function ChargeLocationReviewListPageAll() {
    return (
        <Box sx={{ marginTop: 2 }}>
            <ReviewsListTableComponent filter={{ showProcessed: '1' }} />
        </Box>
    );
}