import { Route } from 'react-router';
import { BrowserRouter, Navigate, Outlet, Routes } from 'react-router-dom';
import { useAuthContext } from './auth/AuthContext';
import LoginPage from './auth/LoginPage';
import DashboardPage from './components/dashboard/DashboardPage';
import Layout from './layout/Layout';
import OperatorListPage from './components/operators/OperatorListPage';
import AddOperatorPage from './components/operators/OperatorCreatePage';
import OperatorDetailsPage from './components/operators/details/OperatorDetailsPage';
import HolderListPage from './components/holders/list/HolderListPage';
import HolderDetailsPage from './components/holders/details/HolderDetailsPage';
import HolderCreatePage from './components/holders/create/HolderCreatePage';
import UserListPage from './components/users/UserListPage';
import UserDetailsPage from './components/users/details/UserDetailsPage';
import UserCreatePage from './components/users/UserCreatePage';
import CustomerDetailsPage from './components/customers/details/CustomerDetailsPage';
import TagListPage from './components/tags/TagListPage';
import TagDetailsPage from './components/tags/TagDetailsPage';
import ChargePointDashboardPage from './components/charge-point/ChargePointDashboardPage';
import TransactionDetailsPage from './components/transaction/TransactionDetailsPage';
import { ChargeLocationDetailsPage } from './components/charge-location/ChargeLocationDetailsPage';
import ChargeLocationListPage from './components/charge-location/ChargeLocationListPage';
import ChargeLocationCreatePage from './components/charge-location/ChargeLocationCreatePage';
import ChargePointCreatePage from './components/charge-point/ChargePointCreatePage';
import ChargePointPricePage from './components/charge-point/tariff/ChargePointPricePage';
import ChargePointLayout from './components/charge-point/ChargePointLayout';
import EventListPage from './components/event-log/EventListPage';
import BillingListPage from './components/billings/pages/BillingListPage';
import ReportListPage from './components/report/ReportListPage';
import ChargePointSettingsPage from './components/charge-point/ChargePointSettingsPage';
import { NotFound404 } from './components/404';
import ChargePointTransactionHistoryPage from './components/charge-point/ChargePointTransactionHistoryPage';
import ChargePointStatsPage from './components/charge-point/ChargePointStatsPage';
import ChargeLocationLayout from './components/charge-location/ChargeLocationLayout';
import { ChargeLocationDashboardPage } from './components/charge-location/ChargeLocationDashboardPage';
import ChargeLocationStatsPage from './components/charge-location/ChargeLocationStatsPage';
import ChargeLocationTransactionHistoryPage from './components/charge-location/ChargeLocationTransactionHistoryPage';
import { TransactionListLayout } from './components/transaction/list-page/TransactionListLayout';
import {
    TransactionListActivePage,
    TransactionListAllPage,
} from './components/transaction/list-page/TransactionListPage';
import { CustomerListLayout } from './components/customers/list/CustomerListLayout';
import { CustomerListAllPage } from './components/customers/list/CustomerListPage';
import { CustomerDetailsLayout } from './components/customers/details/CustomerDetailsLayout';
import CustomerTransactionHistoryPage from './components/customers/details/CustomerTransactionHistoryPage';
import { HolderDetailsLayout } from './components/holders/details/HolderDetailsLayout';
import HolderDetailsLocationsPage from './components/holders/details/HolderDetailsLocationsPage';
import HolderDetailsTransactionHistoryPage from './components/holders/details/HolderDetailsTransactionHistoryPage';
import { UserDetailsLayout } from './components/users/details/UserDetailsLayout';
import { OperatorDetailsLayout } from './components/operators/details/OperatorDetailsLayout';
import StatsPage from './components/stats/main/StatsPage';
import HolderStatsPage from './components/holders/details/HolderStatsPage';
import ChargePointOcppPage from './components/charge-point/ocpp/ChargePointOcppPage';
import { ActionEventListLayout } from './components/event-log/list-page/ActionEventListLayout';
import { ActionEventListAllPage } from './components/event-log/list-page/ActionEventListPage';
import SpecialPage from './components/special/SpecialPage';
import ChargePointUptimePage from './components/charge-point/ChargePointUptimePage';
import UptimePage from './components/stats/uptime/UptimePage';
import BillingDetailsPage from './components/billings/pages/BillingDetailsPage';
import RoamingXPListPage from './components/roaming/list/RoamingXPListPage';
import { ChargeLocationManagementPage } from './components/charge-location/ChargeLocationManagementPage';
import { RoamingXPDetailsLayout } from './components/roaming/details/RoamingXPDetailsLayout';
import { RoamingXPDetailsPage } from './components/roaming/details/RoamingXPDetailsPage';
import RoamingXPDetailsTransactionEMSPHistoryPage from './components/roaming/details/RoamingXPDetailsTransactionEMSPHistoryPage';
import IntegrationListPage from './components/integrations/list/IntegrationListPage';
import { IntegrationDetailsLayout } from './components/integrations/details/IntegrationDetailsLayout';
import { IntegrationDetailsPage } from './components/integrations/details/IntegrationDetailsPage';
import CorporateListPage from './components/corporate/list/CorporateListPage';
import { CorporateDetailsLayout } from './components/corporate/details/CorporateDetailsLayout';
import { CorporateDetailsPage } from './components/corporate/details/CorporateDetailsPage';
import CorporateDetailsTransactionHistoryPage from './components/corporate/details/CorporateDetailsTransactionHistoryPage';
import UserTransactionHistoryPage from './components/users/details/UserTransactionHistoryPage';
import { RoamingXPOcpiPage } from './components/roaming/details/RoamingXPOcpiPage';
import { RoamingXPContactPage } from './components/roaming/details/RoamingXPContactPage';
import { CorporateEmployeesPage } from './components/corporate/details/CorporateEmployeesPage';
import { NavigateToDefaultOperator, WithOperatorContext, WithValidOperatorIdParams } from './auth/OperatorContext';
import { WithGlobalRegistry } from './components/wrappers/WithGlobalRegistry';
import RoamingLocationLayout from './components/roaming-location/RoamingLocationLayout';
import { RoamingLocationDetailsPage } from './components/roaming-location/details/RoamingLocationDetailsPage';
import RoamingLocationTransactionHistoryPage from './components/roaming-location/details/RoamingLocationTransactionHistoryPage';
import { RoamingLocationDashboardPage } from './components/roaming-location/details/RoamingLocationDashboardPage';
import { RoamingLocationStatsPage } from './components/roaming-location/details/RoamingLocationStatsPage';
import { RoamingLocationManagementPage } from './components/roaming-location/details/RoamingLocationManagementPage';
import RoamingXPDetailsTransactionCPOHistoryPage from './components/roaming/details/RoamingXPDetailsTransactionCPOHistoryPage';
import {MorphPhotosListLayout} from './components/morph-photos/MorphPhotosListPage';
import GatesListPage from "./components/gates/GatesListPage";
import ChargeLocationReviewHistoryPage, {
    ChargeLocationReviewListPageAll,
    ChargeLocationReviewListPageProcessed
} from "./components/reviews/components/ChargeLocationReviewHistoryPage";
import ChargeLocationMorphPhotosHistoryPage
    , {
    MorphPhotosListPageAll,
    MorphPhotosListPageProcessed
} from "./components/morph-photos/components/ChargeLocationMorphPhotosHistoryPage";
import {ChargeLocationReviewsListLayout} from "./components/reviews/ReviewsListPage";

function PrivateRoute() {
    const { authorized } = useAuthContext();
    if (!authorized) {
        return <Navigate to="/login" />;
    }
    return <Outlet />;
}

function OperatorLayout() {
    return (
        <WithValidOperatorIdParams>
            <WithOperatorContext>
                <WithGlobalRegistry />
            </WithOperatorContext>
        </WithValidOperatorIdParams>
    );
}

export default function AppRouter() {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<PrivateRoute />}>
                <Route path="" element={<NavigateToDefaultOperator />} />
                <Route path="/:operatorId" element={<OperatorLayout />}>
                    <Route path="" element={<Layout />}>
                        <Route path="" element={<Navigate to="dashboard" />} />
                        <Route path="dashboard" element={<DashboardPage />} />

                        <Route path="photos" element={<MorphPhotosListLayout />}>
                            <Route path="" element={<MorphPhotosListPageProcessed />} />
                            <Route path="processed" element={<MorphPhotosListPageAll />} />
                        </Route>

                        <Route path="reviews" element={<ChargeLocationReviewsListLayout />}>
                            <Route path="" element={<ChargeLocationReviewListPageProcessed />} />
                            <Route path="processed" element={<ChargeLocationReviewListPageAll />} />
                        </Route>

                        <Route path="gates" element={<GatesListPage />} />

                        <Route path="locations" element={<ChargeLocationListPage />} />
                        <Route path="locations/create" element={<ChargeLocationCreatePage />} />
                        <Route path="locations/:id" element={<ChargeLocationLayout />}>
                            <Route path="" element={<ChargeLocationDashboardPage />} />
                            <Route path="settings" element={<ChargeLocationDetailsPage />} />
                            <Route path="transactions" element={<ChargeLocationTransactionHistoryPage />} />
                            <Route path="stats" element={<ChargeLocationStatsPage />} />
                            <Route path="management" element={<ChargeLocationManagementPage />} />
                            <Route path="photos" element={<ChargeLocationMorphPhotosHistoryPage />} />
                            <Route path="reviews" element={<ChargeLocationReviewHistoryPage />} />
                        </Route>
                        <Route path="locations/:id/chargepoint/create" element={<ChargePointCreatePage />} />

                        <Route path="roaminglocations/:id" element={<RoamingLocationLayout />}>
                            <Route path="" element={<RoamingLocationDashboardPage />} />
                            <Route path="transactions" element={<RoamingLocationTransactionHistoryPage />} />
                            <Route path="settings" element={<RoamingLocationDetailsPage />} />
                            <Route path="stats" element={<RoamingLocationStatsPage />} />
                            <Route path="management" element={<RoamingLocationManagementPage />} />
                        </Route>

                        <Route path="operators" element={<OperatorListPage />} />
                        <Route path="operators/create" element={<AddOperatorPage />} />
                        <Route path="operators/:id" element={<OperatorDetailsLayout />}>
                            <Route path="" element={<OperatorDetailsPage />} />
                        </Route>

                        <Route path="holders" element={<HolderListPage />} />
                        <Route path="holders/create" element={<HolderCreatePage />} />
                        <Route path="holders/:id" element={<HolderDetailsLayout />}>
                            <Route path="" element={<HolderDetailsPage />} />
                            <Route path="locations" element={<HolderDetailsLocationsPage />} />
                            <Route path="transactions" element={<HolderDetailsTransactionHistoryPage />} />
                            <Route path="stats" element={<HolderStatsPage />} />
                        </Route>

                        <Route path="users" element={<UserListPage />} />
                        <Route path="users/create" element={<UserCreatePage />} />
                        <Route path="users/:id" element={<UserDetailsLayout />}>
                            <Route path="" element={<UserDetailsPage />} />
                            <Route path="transactions" element={<UserTransactionHistoryPage />} />
                        </Route>

                        <Route path="customers" element={<CustomerListLayout />}>
                            <Route path="" element={<CustomerListAllPage />} />
                        </Route>

                        <Route path="customers/:id" element={<CustomerDetailsLayout />}>
                            <Route path="" element={<CustomerDetailsPage />} />
                            <Route path="transactions" element={<CustomerTransactionHistoryPage />} />
                        </Route>

                        <Route path="corporate" element={<CorporateListPage />} />
                        <Route path="corporate/:id" element={<CorporateDetailsLayout />}>
                            <Route path="" element={<CorporateDetailsPage />} />
                            <Route path="employees" element={<CorporateEmployeesPage />} />
                            <Route path="transactions" element={<CorporateDetailsTransactionHistoryPage />} />
                        </Route>

                        <Route path="tags" element={<TagListPage />} />
                        <Route path="tags/:id" element={<TagDetailsPage />} />

                        <Route path="chargepoint/:id" element={<ChargePointLayout />}>
                            <Route path="" element={<ChargePointDashboardPage />} />
                            <Route path="tariff" element={<ChargePointPricePage />} />
                            <Route path="settings" element={<ChargePointSettingsPage />} />
                            <Route path="transactions" element={<ChargePointTransactionHistoryPage />} />
                            <Route path="stats" element={<ChargePointStatsPage />} />
                            <Route path="ocpp" element={<ChargePointOcppPage />} />
                            <Route path="uptime" element={<ChargePointUptimePage />} />
                        </Route>

                        <Route path="transactions" element={<TransactionListLayout />}>
                            <Route path="" element={<TransactionListAllPage />} />
                            <Route path="active" element={<TransactionListActivePage />} />
                        </Route>

                        <Route path="transactions/:id" element={<TransactionDetailsPage />} />

                        <Route path="events" element={<ActionEventListLayout />}>
                            <Route path="" element={<ActionEventListAllPage />} />
                        </Route>

                        <Route path="billings" element={<BillingListPage />} />
                        <Route path="billings/:id" element={<BillingDetailsPage />} />

                        <Route path="stats" element={<StatsPage />} />
                        <Route path="uptime" element={<UptimePage />} />

                        <Route path="reports" element={<ReportListPage />} />
                        <Route path="special" element={<SpecialPage />} />
                        <Route path="roaming" element={<RoamingXPListPage />} />
                        <Route path="roaming/:id" element={<RoamingXPDetailsLayout />}>
                            <Route path="" element={<RoamingXPDetailsPage />} />
                            <Route path="transactions-emsp" element={<RoamingXPDetailsTransactionEMSPHistoryPage />} />
                            <Route path="transactions-cpo" element={<RoamingXPDetailsTransactionCPOHistoryPage />} />
                            <Route path="ocpi" element={<RoamingXPOcpiPage />} />
                            <Route path="contact" element={<RoamingXPContactPage />} />
                        </Route>

                        <Route path="integration" element={<IntegrationListPage />} />
                        <Route path="integration/:id" element={<IntegrationDetailsLayout />}>
                            <Route path="" element={<IntegrationDetailsPage />} />
                        </Route>
                    </Route>
                </Route>
            </Route>

            <Route path="*" element={<NotFound404 />} />
        </Routes>
    );
}
