import { Title } from '../../elements/Title';
import {
    Alert,
    AlertTitle,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { API } from '../../core/api-client';
import { AccessScope, TransactionFull } from '@electrifly/central-client-api';
import { useEffectOnce } from 'usehooks-ts';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { TransactionActive } from './components/TransactionActive';
import { TransactionPriceResource } from './components/TransactionPrice';
import { WebsocketClient } from '../../core/ws-client';
import { useChargePointRemoteStopDialog } from '../charge-point/dialogs/ChargePointRemoteStop';
import { useTransaction, WithTransaction } from '../wrappers/WithTransaction';
import { TransactionTime } from './elements/TransactionTime';
import { TransactionDuration } from './elements/TransactionDuration';
import { ChargeLocationLink } from '../charge-location/elements/ChargeLocationLink';
import { ChargePointLink } from '../charge-point/elements/ChargePointLink';
import { ConnectorTypeText } from '../connector/Standard';
import { TagTypeIcon } from '../tags/elements/TokenTypeText';
import { TransactionMeasurement } from './components/TransactionMeasurement';
import { createWithImmer } from '../../misc/CreateWithImmer';
import { useTotalCost } from './hooks/useTotalCost';
import BigNumber from 'bignumber.js';
import { OwnerLink, useOwnerType } from '../links/OwnerLink';
import { TransactionEnergyKWh } from './elements/TransactionEnergy';
import { TransactionReInvoiceDialogHelper } from './dialogs/TransactionReInvoiceDialog';
import { BillingList } from '../billings/components/BillingList';
import { useAccesScope } from '../wrappers/WithAccessScope';
import AccountDetails from '../account/AccountDetails';
import { WithAccountLoader } from '../wrappers/WithAccountLoader';
import { WithAccount } from '../wrappers/WithAccount';
import { RoamingLocationLink } from '../roaming-location/elements/RoamingLocationLink';

interface PageService {
    transaction?: TransactionFull;
    loading: boolean;

    reset: () => void;
    loadInformation: (_id: string) => Promise<void>;
}

const usePageService = createWithImmer<PageService>((set, get) => {
    WebsocketClient.events.TRANSACTION.on(updatedTransaction => {
        const transaction = get().transaction;
        if (transaction?._id !== updatedTransaction._id) {
            return;
        }

        set(draft => {
            if (!draft.transaction) {
                return;
            }
            draft.transaction = { ...updatedTransaction, measurements: draft.transaction.measurements };
        });
    });

    WebsocketClient.events.TRANSACTION_MEASUREMENT.on(updatedTransaction => {
        const transaction = get().transaction;
        if (transaction?._id !== updatedTransaction._id) {
            return;
        }

        set(draft => {
            if (!draft.transaction) {
                return;
            }
            draft.transaction.measurements = updatedTransaction.measurements;
        });
    });

    return {
        transaction: undefined,
        loading: false,

        reset: () => set({ transaction: undefined, loading: false }),

        loadInformation: async (id: string) => {
            if (get().loading) {
                return;
            }
            set({ loading: true });
            const [error, res] = await API.transactionDetails(id);
            set({ loading: false });

            if (error) {
                console.error(error);
                return;
            }

            set({ transaction: res.data });
        },
    };
});

function ManualModeBlock() {
    const transaction = useTransaction();

    if (transaction.manualMode !== true) {
        return null;
    }

    return (
        <Stack spacing={2}>
            <Alert severity="info" sx={{ display: 'flex', width: '100%' }}>
                <AlertTitle>Транзакция создана в ручном режиме</AlertTitle>
                <Stack direction={'column'}>
                    <Typography fontSize={'inherit'}>
                        Установленное время старта: <TransactionTime time={transaction.originStartTime} />
                    </Typography>
                    <Typography fontSize={'inherit'}>
                        Установленное время завершения: <TransactionTime time={transaction.originEndTime} />
                    </Typography>
                </Stack>
            </Alert>
        </Stack>
    );
}

function RefundedBlock() {
    const transaction = useTransaction();

    if (transaction.refunded !== true) {
        return null;
    }

    return (
        <Stack spacing={2}>
            <Alert severity="error" sx={{ display: 'flex', width: '100%' }}>
                <AlertTitle>Оформлен возврат</AlertTitle>
                <Stack direction={'column'}>
                    <Typography fontSize={'inherit'}>{transaction.refundReason}</Typography>
                </Stack>
            </Alert>
        </Stack>
    );
}

function ActionBlock() {
    const transaction = useTransaction();
    const showRemoteStopDialog = useChargePointRemoteStopDialog(store => store.show);
    const isCompleted = useMemo(() => !!transaction.completed, [transaction.completed]);

    return (
        <Grid item lg={6} xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack spacing={2}>
                <List disablePadding>
                    {!isCompleted && (
                        <ListItem component={Paper}>
                            <ListItemText
                                primary="Остановка транзакции"
                                secondary="Принудительное завершние администратором"
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={() => showRemoteStopDialog(transaction.transactionId)}
                                >
                                    Остановить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                    {isCompleted && (
                        <ListItem component={Paper}>
                            <ListItemText
                                primary="Переоформления счёта"
                                secondary="Оформить новый счёт/показания по зарядной сессии"
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={() => TransactionReInvoiceDialogHelper.show(transaction.transactionId)}
                                >
                                    Оформить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
            </Stack>
        </Grid>
    );
}

function BillingsBlock() {
    const transaction = useTransaction();
    const allowed = useAccesScope(AccessScope.OPERATOR);

    if (!allowed) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <AccountBlock />
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Биллинг</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <BillingList filter={{ account: transaction.account }} />
        </Grid>
    );
}

function AccountBlock() {
    const transaction = useTransaction();
    const allowed = useAccesScope(AccessScope.OPERATOR);

    if (!allowed) {
        return null;
    }

    return (
        <WithAccountLoader id={transaction.account}>
            {account => (
                <WithAccount account={account}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Аккаунт</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <AccountDetails account={account} />
                </WithAccount>
            )}
        </WithAccountLoader>
    );
}

function LimitsBlock() {
    const transaction = useTransaction();

    const hasConstraint = useMemo(() => {
        return !!(transaction.constraintWh || transaction.constraintMinutes || transaction.constraintSOC);
    }, [transaction.constraintMinutes, transaction.constraintSOC, transaction.constraintWh]);

    if (!hasConstraint) {
        return (
            <List component={Paper} disablePadding>
                <ListItem>
                    <ListItemText primary="Без ограничений" />
                </ListItem>
            </List>
        );
    }

    return (
        <List component={Paper} disablePadding>
            {transaction.constraintWh && (
                <ListItem divider>
                    <ListItemText primary="Энергия" />
                    <ListItemSecondaryAction>
                        <TransactionEnergyKWh energy={transaction?.constraintWh} unit={'Wh'} />
                    </ListItemSecondaryAction>
                </ListItem>
            )}
            {transaction.constraintMinutes && (
                <ListItem divider>
                    <ListItemText primary="Длительность" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={`${transaction.constraintMinutes} мин`} />
                    </ListItemSecondaryAction>
                </ListItem>
            )}
            {transaction.constraintSOC && (
                <ListItem divider>
                    <ListItemText primary="SoC" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={`${transaction.constraintSOC} %`} />
                    </ListItemSecondaryAction>
                </ListItem>
            )}
        </List>
    );
}

function TariffBlock() {
    const transaction = useTransaction();

    const totalCost = useTotalCost(transaction);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Тариф</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary="Тариф" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={transaction.tariff?.text} />
                    </ListItemSecondaryAction>
                </ListItem>
                {transaction.isAudiBonus && (
                    <ListItem divider>
                        <ListItemText primary="Дополнительно" />
                        <ListItemSecondaryAction>
                            <ListItemText primary={`Бонусная программа Ауди Центр Самара`} />
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
                {transaction.isEPROMBonus && (
                    <ListItem divider>
                        <ListItemText primary="Дополнительно" />
                        <ListItemSecondaryAction>
                            <ListItemText primary={`Бесплатный тариф EPROM`} />
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
                <ListItem>
                    <ListItemText primary="Стоимость" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={`${totalCost} ₽`} />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </>
    );
}

function TransactionDetailsInternal() {
    const transaction = useTransaction();

    const powerActiveMaxKW = useMemo(() => {
        return transaction.powerActiveMax ? new BigNumber(transaction.powerActiveMax).div(1000).toFormat(2) : '?';
    }, [transaction.powerActiveMax]);

    const ownerType = useOwnerType(transaction.tokenVariant);

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Транзакция #{transaction?.transactionId}</Title>
            </Stack>

            <ManualModeBlock />
            <RefundedBlock />

            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Информация</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <List component={Paper} disablePadding>
                        <ListItem divider>
                            <ListItemText primary="Статус" />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={transaction?.completed ? 'Завершена' : <TransactionActive mode="full" />}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Начало" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={<TransactionTime time={transaction.startTime} />} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Завершение" />
                            <ListItemSecondaryAction>
                                {transaction.completed && (
                                    <ListItemText primary={<TransactionTime time={transaction.endTime} />} />
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Длительность" />
                            <ListItemSecondaryAction>
                                <TransactionDuration
                                    startTime={transaction.startTime}
                                    endTime={transaction.endTime}
                                    completed={transaction.completed}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem divider>
                            <ListItemText primary="Счётчик | начало" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={`${transaction?.meterStart} Вт⋅ч`} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Счётчик | завершение" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={`${transaction?.meterStop} Вт⋅ч`} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Причина завершения" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={`${transaction.stopReason || ''}`} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="SoC" />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={`${transaction.socStart ?? '?'} → ${transaction.socEnd ?? '?'}`}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Пиковая мощность" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={`${powerActiveMaxKW} кВт`} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Энергия" />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={<TransactionEnergyKWh energy={transaction?.meterDiff} unit={'Wh'} />}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <TariffBlock />
                </Grid>

                <Grid item lg={6} xs={12}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Авторизация</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <List component={Paper} disablePadding>
                        <ListItem divider>
                            <ListItemText primary="Клиент" />
                            <ListItemSecondaryAction>
                                <OwnerLink
                                    type={ownerType}
                                    owner={transaction.owner}
                                    customerLinkProps={{ displayEMSP: true }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Аккаунт" />
                            <ListItemSecondaryAction>
                                <WithAccountLoader id={transaction.ownerAccount}>
                                    {account => <OwnerLink type={account.ownerType} owner={account.owner} />}
                                </WithAccountLoader>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Тип авторизации" />
                            <ListItemSecondaryAction>
                                {transaction.authorizationTokenType && <TagTypeIcon type={transaction.tokenType} />}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Тэг" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={transaction?.idTag} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>

                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Зарядная локация</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <List component={Paper} disablePadding>
                        <ListItem divider>
                            <ListItemText primary="Тип инфраструктуры" />
                            <ListItemSecondaryAction>
                                <Typography fontSize={'inherit'}>{transaction.infrastructureType}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Зарядная локация" />
                            <ListItemSecondaryAction>
                                {transaction.infrastructureType === 'internal' ? (
                                    <ChargeLocationLink id={transaction.chargeLocation} />
                                ) : (
                                    <RoamingLocationLink id={transaction.chargeLocation} />
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Cтанция" />
                            <ListItemSecondaryAction>
                                {transaction.infrastructureType === 'internal' ? (
                                    <ChargePointLink id={transaction.chargePoint} />
                                ) : (
                                    <Typography fontSize={'inherit'}>---</Typography>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Коннектор" />
                            <ListItemSecondaryAction>
                                <Stack
                                    direction={'row'}
                                    alignItems="center"
                                    spacing={1}
                                    divider={<Divider orientation="vertical" flexItem />}
                                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                                >
                                    <Typography fontSize={'inherit'}>{transaction.connectorSeqNumber}</Typography>
                                    <Typography fontSize={'inherit'}>
                                        <ConnectorTypeText type={transaction.standard} />
                                    </Typography>
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>

                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Лимиты</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <LimitsBlock />
                </Grid>
                <BillingsBlock />
                <ActionBlock />

                <Grid item xs={12}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Измерения</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <TransactionMeasurement />
                </Grid>
            </Grid>
        </>
    );
}

export type TransactionDetailsPageParams = {
    id: string;
};

export default function TransactionDetailsPage() {
    const { id } = useParams<TransactionDetailsPageParams>();

    const reset = usePageService(store => store.reset);
    const loadInformation = usePageService(store => store.loadInformation);

    const transaction = usePageService(store => store.transaction);

    useEffectOnce(() => {
        loadInformation(id!);
        return () => reset();
    });

    if (!transaction) {
        return null;
    }

    return (
        <WithTransaction transaction={transaction}>
            <TransactionDetailsInternal />
        </WithTransaction>
    );
}
