import { Alert, Button, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useChargePoint } from '../wrappers/WithChargePoint';
import { useChargePointEditCommentDialog } from './dialogs/ChargePointEditCommentDialog';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {useChargeLocationEditInfoMessageDialog} from "./dialogs/ChargePointEditInfoMessageDialog";
import {useChargeLocation} from "../wrappers/WithChargeLocation";

function EditCommentButton() {
    const chargePoint = useChargePoint();
    const showChargePointEditCommentDialog = useChargePointEditCommentDialog(store => store.show);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return (
            <IconButton color="inherit" size="small" onClick={() => showChargePointEditCommentDialog(chargePoint._id)}>
                <EditRoundedIcon />
            </IconButton>
        );
    }

    return (
        <Button color="inherit" size="small" onClick={() => showChargePointEditCommentDialog(chargePoint._id)}>
            Редактировать
        </Button>
    );
}

function EditInfoMessageButton() {
    const chargePoint = useChargePoint();
    const showChargePointEditInfoMessageDialog = useChargeLocationEditInfoMessageDialog(store => store.show);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return (
            <IconButton color="inherit" size="small" onClick={() => showChargePointEditInfoMessageDialog(chargePoint._id)}>
                <EditRoundedIcon />
            </IconButton>
        );
    }

    return (
        <Button color="inherit" size="small" onClick={() => showChargePointEditInfoMessageDialog(chargePoint._id)}>
            Редактировать
        </Button>
    );
}

export function CommentInfoBlock() {
    const chargePoint = useChargePoint();

    return (
        <Stack spacing={2}>
            <Alert
                severity="warning"
                sx={{ display: 'flex', width: '100%', whiteSpace: 'pre-line' }}
                action={<EditCommentButton />}
            >
                {chargePoint.comment}
            </Alert>
        </Stack>
    );
}

export function InfoMessageBlockForChargeLocation() {
    const location = useChargeLocation();

    return (
        <Stack spacing={2}>
            <Alert
                severity="info"
                sx={{ display: 'flex', width: '100%', whiteSpace: 'pre-line' }}
                action={<EditInfoMessageButton />}
            >
                Комментарий который видят пользователи: {location.infoMessage}
            </Alert>
        </Stack>
    );
}
