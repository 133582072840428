import {
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import {useNavigate} from 'react-router-dom';
import {Options} from "../../event-log/components/table/ListPageService";
import React, {PropsWithChildren} from 'react';
import {useOperatorId} from "../../../hooks/useOperatorId";
import {ReviewService} from "./ReviewService";
import {useEffectOnce} from 'usehooks-ts';
import {RouterLink} from "../../../elements/RouterLink";
import {HumanDate} from "../../mixin/HumanDate";
import {ReviewsList} from '@electrifly/central-client-api';

interface ReviewTableItemProps {
    review: any
    showHideButton?: boolean
}

function ReviewTableItem({review, showHideButton}: ReviewTableItemProps) {
    const navigate = useNavigate();
    const operatorId = useOperatorId()
    const toggleStatus = ReviewService.useStore(store => store.toggleApprovedStatus);
    const toggleSavedAndHiddenStatus = ReviewService.useStore(store => store.toggleSavedAndHiddenStatus);

    return (
        <TableRow key={review._id} hover={true}
                  sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: 'none'}}
        >
            <TableCell width={'15%'}>
                <HumanDate time={review.created}/>
            </TableCell>
            <TableCell width={'15%'}>
                {
                    showHideButton ? null : (<>
                        <RouterLink to={`/${operatorId}/locations/${review.location}/reviews`}>
                                    {review.locationName}
                        </RouterLink>
                        <br/>
                        <br/>
                    </>)
                }
                <RouterLink to={`/${operatorId}/customers/${review.account}`}>
                    {review.accountName}
                </RouterLink>
            </TableCell>
            <TableCell>
                {
                    !review.transaction ? 'Отсутствует' : (
                        <RouterLink to={`/${operatorId}/transactions/${review.transaction}`}>
                            {review.transaction}
                        </RouterLink>
                    )
                }
            </TableCell>
            <TableCell>
                {review.stars}
            </TableCell>
            <TableCell>
                {review.message}
            </TableCell>
            <TableCell>
                <Grid
                    container
                    direction="column"
                    sx={{
                        justifyContent: "center",
                        alignItems: "flex-start",
                    }}
                >
                    <Button onClick={() => toggleStatus(review._id)} variant="contained"
                            color={review.isApproved ? 'warning' : 'success'}>
                        {review.isApproved ? 'Запретить' : 'Разрешить'}
                    </Button>

                    {
                        !showHideButton && (
                            <Button style={{marginTop: 15}} onClick={() => toggleSavedAndHiddenStatus(review._id)}
                                    variant="contained" color={review.isSavedAndHidden ? 'warning' : 'success'}>
                                {'Сохранить и скрыть'}
                            </Button>
                        )
                    }
                </Grid>
            </TableCell>
        </TableRow>
    );
}

interface ChargeLocationTableProps {
    showHiddenButton?: boolean
}

function MorphPhotosTable({showHiddenButton}: ChargeLocationTableProps) {
    const reviews = ReviewService.useStore(store => store.reviews);

    // const useLocations = useChargeLocation()

    // console.log(useLocations);

    // console.log(photos);

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell size={'small'}>{ showHiddenButton ? 'Клиент' : 'Адрес' }</TableCell>
                        <TableCell>Транзакция</TableCell>
                        <TableCell>Оценка</TableCell>
                        <TableCell>Сообщение</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        reviews.map((review, key) => (
                            <ReviewTableItem showHideButton={showHiddenButton} review={review}
                                             key={key}></ReviewTableItem>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export interface Filter {
    operator?: string;
    chargeLocationId?: string;
    holder?: string;
}

interface Props {
    filter?: Filter;
}

function LoadWrapper({children}: PropsWithChildren<{}>) {
    const loadNext = ReviewService.useStore(store => store.loadNext);
    const reset = ReviewService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface ActionEventListTableProps {
    filter?: ReviewsList.ReqQuery;
    options?: Partial<Options>;
}

export default function ReviewsListTableComponent({filter = {}, options = {}}: ActionEventListTableProps) {
    // const filteredLocationIds = useFilteredLocationIds(filter);

    return <ReviewService.Provider createStore={() => ReviewService.createStore(filter, {})}>
        <LoadWrapper>
            <MorphPhotosTable showHiddenButton={filter.locationId !== undefined}/>
        </LoadWrapper>
    </ReviewService.Provider>
}
